// 加载 request 模块
import {request} from '@/utils'

export const get_setting = params => {
    return request({
        method: 'GET',
        url: '/api/get_setting',
        params,
    })
}

export const set_setting = params => {
    return request({
        method: 'GET',
        url: '/api/set_setting',
        params,
    })
}

export const get_queue = params => {
    return request({
        method: 'GET',
        url: '/api/get_queue',
        params,
    })
}

export const get_userinfo = params => {
    return request({
        method: 'GET',
        url: '/api/get_userinfo',
        params,
    })
}

export const get_invite = params => {
    return request({
        method: 'GET',
        url: '/api/get_invite',
        params,
    })
}

export const set_invite = params => {
    return request({
        method: 'GET',
        url: '/api/set_invite',
        params,
    })
}

// post请求
// export const hello1 = data => {
//   return request({
//     method: 'POST',
//     url: '/hello/hello1',
//     data,
//   })
// }
