<!-- 布局容器 -->
<template>
  <div class="layout">
<!--    <van-notice-bar-->
<!--        left-icon="volume-o"-->
<!--        wrapable-->
<!--        :scrollable="false"-->
<!--        text="由于游戏更新，游戏部分功能更新中, 预计今天24点前更新完毕。。。"-->
<!--    />-->

    <van-nav-bar title="清风小助手" left-text="推广" @click-left="onClickLeft" right-text="设置" @click-right="showPopup">

    </van-nav-bar>

    <van-dialog v-model="showDialog" title="推广福利" @confirm="dialog_confirm">
      <van-notice-bar text="介绍其他玩家购买(月卡/季卡)，本号可以获得月卡1️⃣张"/>
      <van-col span="24" style="display: flex;align-items: center;">
        <van-button style="width: 100%; margin: 8px; " size="small" round plain type="info"
                    @click="on_copy_url">
          复制推广链接
        </van-button>
      </van-col>

      <van-notice-bar style="margin: 8px" type="danger">已邀请：{{this.inviteList}}</van-notice-bar>
      <van-search v-model="inviteId" style="margin-bottom: 16px"  type="digit" placeholder="请输入对方领主账号" />

    </van-dialog>

    <van-pull-refresh v-model="isLoading" @refresh="onRefresh">
      <van-list v-model="loading"
                :finished="finished"
                finished-text="下拉刷新"
                @load="onLoad">
        <van-cell-group class="cell-group" title="账号信息" inset>
          <van-cell
              :title="''+this.user_info.name + ' ['+this.user_info.vip_card +']' + (this.user_info.err_sdktoken == 0 ? ' 😊' : ' 😭')"
              :label="'账号:'+ this.user_info.fid + /*'｜联盟:'+ this.user_info.abbr  + */'｜到期:'+ this.user_info.expire_time">
          </van-cell>
          <van-cell title="续费/售后/客服" is-link url="https://work.weixin.qq.com/kfid/kfc07854096577173ea"/>
        </van-cell-group>

        <van-row gutter="48" type="flex" justify="center" style="margin: 16px">
          <van-col span="24">
            <van-button style="width: 100%;" size="small" round plain type="info" @click="on_setting_sync_click">
              同步数据
            </van-button>
          </van-col>
        </van-row>


        <van-cell-group class="cell-group" v-for="item in queue" :key="item.id" :title="item.name" inset>
          <van-cell title-class="cell-title" value-class="cell-value" v-for="item in item.list" :key="item.id">
            <template #default>
                  <span>
                    {{ item.comment }}
                  </span>
            </template>
            <template #title>
              <van-tag type="primary" plain mark>{{ item.title }}</van-tag>
            </template>
          </van-cell>
        </van-cell-group>
      </van-list>
    </van-pull-refresh>

    <van-popup v-model="show" position="left" :style="{ width: '75%', height: '100%' }">
      <van-divider :style="{ color: '#1989fa', borderColor: '#1989fa', padding: '0 16px' }">
        游戏设置
      </van-divider>
      <van-cell v-for="item in setting" :key="item.name" :title="item.comment" inset>
        <template v-if="item.type === 0" #right-icon>
          <van-switch @change="on_switch_change(item.value, item.name)" :name="item.name" v-model="item.value"
                      size="24"/>
        </template>
        <template v-else-if="item.type === 1" #right-icon>
          <van-stepper @change="on_stepper_change(item.value, item.name)" :min="item.min" :max="item.max"
                       :step="item.step" :name="item.name" v-model="item.value" integer theme="round" input-width="72px"
                       button-size="24px"/>
          <!--          <van-stepper @change="on_stepper_change" :min="item.min" :max="item.max" :step="item.step" :name="item.name" input-width="72px" integer="true" v-model="item.value" button-size="24"/>-->
        </template>
      </van-cell>

    </van-popup>
  </div>


</template>

<script>
import Vue from 'vue';
import {NavBar} from 'vant';
import {Cell, CellGroup, PullRefresh} from 'vant';
import {Progress} from 'vant';
import {Tag} from 'vant';
import {Col, Row} from 'vant';
import {Toast} from 'vant';
import {Popup} from 'vant';
import {Switch} from 'vant';
import {Stepper} from 'vant';
import {Dialog} from 'vant';

import {Search} from 'vant';

Vue.use(Search);

import {NoticeBar} from 'vant';

Vue.use(NoticeBar);

Vue.use(NavBar);
Vue.use(PullRefresh);
Vue.use(Cell);
Vue.use(CellGroup);
Vue.use(Tag);
Vue.use(Toast);
Vue.use(Progress);
Vue.use(Col);
Vue.use(Row);
Vue.use(Popup);
Vue.use(Stepper);
Vue.use(Switch);
Vue.use(Dialog);

import {get_setting, set_setting, get_queue, get_userinfo, set_invite, get_invite} from '@/api/index';

export default {
  name: 'layout',
  components: {},
  props: {},
  data() {
    return {
      user_info: {},
      setting: [],
      queue: [],
      loading: false,
      finished: false,
      isLoading: false,
      show: false,
      showDialog: false,
      uid: '',
      inviteId: '',
      inviteList: '[]'
    }
  },
  computed: {},
  watch: {},
  created() {
    Toast.setDefaultOptions({duration: 333});
  },
  mounted() {
    this.uid = /[a-z0-9]+/i.exec(this.$route.params["id"]).pop()
  },
  methods: {
    dialog_confirm(){
      if (this.inviteId !== ''){
        set_invite({uid: this.uid, inviteId: this.inviteId}).then(data => {
          this.$toast({message: data + '', duration: 2000});
        }).catch(e => {
          this.$toast('失败');
        })
      }else {
        Toast({message: '对方领主账号为空', duration: 1000});
      }
    },
    on_copy_url() {
      this.$copyText('【淘宝】http://e.tb.cn/h.goqypg5XcZd8AlA?tk=Tg3l3gJQSFH CZ0016 「清风小助手补差价专拍」\n' +
          '点击链接直接打开 或者 淘宝搜索直接打开').then(
          function (e) {
            Toast({message: '复制成功', duration: 2000});
          },
          function (e) {
            Toast.fail('复制失败');
          }
      );
    },
    onClickLeft() {
      this.inviteId = ''
      this.showDialog = true
      get_invite({uid: this.uid}).then(data => {
        if (data + ''!== ''){
          this.inviteList = data
        }
      }).catch(e => {
        this.$toast('失败');
      })
    },
    onLoad() {
      this.queue = [];
      get_queue({uid: this.uid}).then(data => {
        for (let i in data) {
          this.queue.push(data[i])
        }
      }).catch(e => {
      }).finally(() => {
        this.isLoading = false
        this.loading = false;
        this.finished = true;
      })

      get_userinfo({uid: this.uid}).then(data => {
        for (let k in data) {
          Reflect.set(this.user_info, k, data[k])
        }
      }).catch(e => {
      })

    },
    showPopup() {
      this.show = true;
      get_setting({uid: this.uid}).then(data => {
        this.setting = [];
        for (const i in data) {
          this.setting.push(data[i]);
        }
        if (this.setting.length > 0) {
          this.show = true
        } else {
          this.$toast('加载参数失败');
        }
      }).catch(e => {
        this.$toast('加载参数失败');
      })
    },
    onRefresh() {
      this.isLoading = true
      this.onLoad();
    },

    on_setting_sync_click() {

      set_setting({uid: this.uid, name: "2ab56f049e45f515", value: 1}).then(data => {
        this.$toast(data > 0 ? '成功' : '失败');
      }).catch(e => {
        this.$toast('失败');
      })

    },

    on_stepper_change(v, n) {
      set_setting({uid: this.uid, name: n, value: v}).then(data => {
        this.$toast(data > 0 ? '成功' : '失败');
      }).catch(e => {
        this.$toast('失败');
      })
    },

    on_switch_change(v, n) {
      set_setting({uid: this.uid, name: n, value: v}).then(data => {
        this.$toast(data > 0 ? '成功' : '失败');
      }).catch(e => {
        this.$toast('失败');
      }).finally(() => {
        // get_setting({uid: this.uid}).then(data => {
        //   this.setting = [];
        //   for (const i in data) {
        //     this.setting.push(data[i]);
        //   }
        // })
      })
    }
  },
}
</script>

<style scoped lang="less">
.layout {
  margin: 8px;
  background-color: #f0f0f0;
}

.custom-title {
  margin-right: 4px;
  vertical-align: middle;
}

.search-icon {
  font-size: 16px;
  line-height: inherit;
}

</style>
